import './App.css';
import Footer from './components/Footer/Footer';
import Hero from './components/Hero/Hero';
import Join from './components/Join/Join';
import Planes from './components/Planes/Planes';
import Programs from './components/programs/programs';
import Reasons from './components/Reasons/Reasons';
import Testimonials from './components/Testimonial/Testimonials';

function App() {
  return (
    <div className='App'>
    <Hero/>
    <Programs/>
    <Reasons/>
    <Planes/>
    <Testimonials/>
    <Join/>
    <Footer />
    </div>
  );
}

export default App;